.testRulesSelected {
  .ruleSummary {
    button {
      width: 30px;
      height: 30px;
      margin-right: 8px;
    }
  }
  .title {
    width: calc(100% - 50px);
    font-family: Inter;
    max-height: 40px;
    margin-right: 8px;
  }
  .editorHead {
    width: 100%;
    font-size: 12px;
    height: 24px;
    background: #4E4E4E;
    color: #CCCCCC;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 2px 8px;
  }
  .editorBody {
    height: auto;
    background: #2D2D2D;
    border: none;
    outline: none;
    color: white;
    width: 100%;
  }
}
