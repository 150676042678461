.full-height {
  height: 100vh;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.confirmation-card {
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.confirmation-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.confirmation-message,
.confirmation-error {
  margin-bottom: 10px;
  text-align: center;
}

.confirmation-card a {
  color: #6241D4 !important;
  text-decoration: none !important;
  transition: color 0.3s !important;
}

.confirmation-card a:hover {
  color: #0d47a1 !important;
}
