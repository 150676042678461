/* Typography */
.first_class {
  font-size: 20px;
  font-weight: 700;
  color: green;
}

/* Flexbox Utilities */
.flex {
  display: flex;
  align-items: center;
}

.justify-center,
.justify-between,
.justify-arround {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-arround {
  justify-content: space-around;
}

/* Button Styles */
.an_primary_button,
.an_secondary_button {
  box-sizing: border-box;
  text-align: center;
  text-transform: capitalize;
  font-weight: bold;
}

.an_primary_button {
  background-color: $primaryColor;
  color: white;
  border: none;
}

.an_primary_button:hover {
  background: rgba(0, 68, 239, 0.757);
  cursor: pointer;
}

.an_secondary_button {
  background: #FFFFFF;
  border: 1px solid #00C4B5;
  color: #00C4B5;
}

.an_secondary_button:hover {
  background: rgba(0, 196, 181, 0.1) !important;
  border: 1px solid transparent;
  cursor: pointer;
}

/* Button Sizes */
.small_less_padding_btn,
.small_btn,
.medium_btn,
.big_btn,
.very_big_btn,
.large_btn {
  border-radius: 3px;
}

.small_less_padding_btn {
  padding: 8px 18px;
  font-size: 14px;
  @media screen and (max-width: $screen_lg) {
      padding: 6px 10px;
      font-size: 12px;
  }
}

.small_btn {
  padding: 7px 42px;
  height: 32px;
  font-size: 14px;
}

.medium_btn {
  padding: 9px 42px;
  height: 36px;
  font-size: 14px;
  @media screen and (max-width: $screen_md) {
      padding: 8px 19px;
  }
}

.big_btn {
  padding: 10px 58px;
  height: 40px;
  font-size: 16px;
  border-radius: 4px;
}

.very_big_btn {
  padding: 14px 58px;
  height: 48px;
  font-size: 16px;
  border-radius: 4px;
}

.large_btn {
  padding: 16px 74px;
  height: 56px;
  font-size: 18px;
  border-radius: 4px;
}

/* Grid Styles */
.grid-container {
  display: inline-grid;
  grid-template-columns: 6fr 1fr;
  width: 100%;
}

.grid-item {
  text-align: left;
}

/* Button Disabled States */
.an_primary_button_disabled,
.an_secondary_button_disabled {
  cursor: not-allowed;
}

.an_primary_button_disabled {
  background: rgba(0, 196, 181, 0.4);
  color: rgba(255, 255, 255, 0.8);
}

.an_secondary_button_disabled {
  background: #FFFFFF;
  border: 1px solid rgba(0, 196, 181, 0.4);
  color: rgba(0, 196, 181, 0.4);
}

/* Animations */
@keyframes loader-box-1 {
  0% {opacity: 0.33}
  33% {opacity: 1}
  66% {opacity: 0.66}
  100% {opacity: 0.33}
}

@keyframes loader-box-2 {
  0% {opacity: 0.66}
  33% {opacity: 0.33}
  66% {opacity: 1}
  100% {opacity: 0.66}
}

@keyframes loader-box-3 {
  0% {opacity: 1}
  33% {opacity: 0.66}
  66% {opacity: 0.33}
  100% {opacity: 1}
}

/* Loader Mixin */
@mixin loader_box($size, $color) {
  .loader-box {
      height: $size;
      width: $size;
      background-color: $color;
      float: left;
      &.loader_box1 {
          animation-name: loader-box-1;
          animation-duration: 1s;
          animation-iteration-count: infinite;
      }
      &.loader_box2 {
          margin: 0px $size;
          animation-name: loader-box-2;
          animation-duration: 1s;
          animation-iteration-count: infinite;
      }
      &.loader_box3 {
          animation-name: loader-box-3;
          animation-duration: 1s;
          animation-iteration-count: infinite;
      }
  }
}

.loader-green4 {
  @include loader_box(4px, #00c4b5);
}

.loader-white4 {
  @include loader_box(4px, #FFF);
}

.loader-white16 {
  @include loader_box(16px, #FFF);
}

/* Miscellaneous Styles */
.hidden_text {
  visibility: hidden;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.hover_under_line {
  text-decoration: none;
  cursor: pointer;
  &:hover {
      text-decoration: underline;
  }
}

.footer_container {
  position: fixed;
  bottom: 0px;
  background: #F8F8F8;
  width: 100%;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 0px;
  padding: 8px 36px;
  box-sizing: border-box;
  z-index: 999;
}
