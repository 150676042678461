.qodexBot {
  padding: 16px;
  width: 375px;
  height: 650px;
  overflow: hidden;
  font-family: "Inter";
  .heading {
    width: 50%;
    display: inline-block;
    padding-bottom: 8px;
    &.rightSide {
      text-align: right;
      svg {
        cursor: pointer;
      }
    }
    img {
      height: 34px;
      display: inline-block;
      width: 34px;
      border-radius: 50%;
      box-shadow: 0px 0px 3px 0px #4d4d4d;
      margin-right: 6px;
    }
  }
  .body {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    overflow: auto;
    height: calc(100% - 90px);
  }
  .footer {
    position: absolute;
    bottom: 16px;
    width: calc(100% - 32px);
    // border-top: 1px solid #e4e4e4;
    padding-top: 8px;
    input {
      width: calc(100% - 48px);
      border: none;
      outline: none;
    }
    button {
      width: 36px;
      height: 32px;
      margin-left: 12px;
      border-radius: 4px;
      box-shadow: none;
      svg {
        font-size: 24px;
      }
    }
  }
  div {
    font-family: "Inter";
  }
  .bold {
    font-weight: 600;
  }
  .chatContainer {
    overflow: auto;
  }
  .chipContainer {
    padding: 4px 8px;
    border: 1px solid #f7f7f7;
    border-radius: 4px;
    margin: 4px 0 0;
    color: #6241d4;
    background : #fbfbfb;
    &.rightSide {
      .chip {
        color: black;
        background: #f5f5f5;
      }
    }
    .chip {
      margin: 4px 4px 4px 0;
      border-radius: 4px;
      color: black;
      background : white;
    }
  }
  .time {
    font-size: 10px;
    &.rightSide {
      text-align: right;
    }
  }
  .processContainer {
    padding: 4px 8px;
    border: 1px solid #f7f7f7;
    border-radius: 4px;
    margin: 4px 0;
    color: #6241d4;
    background : #fbfbfb;
    // &.rightSide {
    //   color: black;
    //   background : #fbfbfb;
    // }
    .element {
      padding: 8px;
      border-top: 1px solid #e4e4e4;
    }
    .text {
      padding: 8px;
    }
  }
  .textMessage {
    padding: 8px;
    border-radius: 4px;
    color: white;
    background : #6241d4;
    border: 1px solid #f7f7f7;
    &.rightSide {
      color: black;
      background : #fbfbfb;
    }
    &.error {
      color: #fff;
      background: #dd181b;
    }
  }
  .contentContainer {
    margin: 8px 0;
    width: 80%;
  }
  .emptyChat {
    text-align: center;
    padding: 8px;
    height: 300px;
    img {
      height: 50px;
      margin-bottom: 8px;
      display: inline-block;
      width: 50px;
      border-radius: 50%;
      box-shadow: 0px 0px 3px 0px #4d4d4d;
    }
  }
}
