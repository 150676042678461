.proecessingShimmer {
  svg {
    width: 36px;
    height: 36px;
    color: #6241d4;
    margin-right: 8px;
    opacity: 1;
    animation: shimmer 0.4s infinite;
  }
}
@keyframes shimmer {
  100% {opacity: 0.2;}
}