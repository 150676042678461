.testCaseSet {
  .testCaseSetAccordion {
    box-shadow: none;
  }
  .testCaseSetSummary {
    // background-color: white;
    &:hover {
      background-color: #FAFAFA;
      box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
      border-radius: 4px;
      .dragIcon, .expandIcon, .menuIcon {
        visibility: visible;
      }
      &.expanded {
        background-color: #FAFAFA;
        box-shadow: none;
      }
    }
    .dragIcon, .expandIcon, .menuIcon {
      visibility: hidden;
    }
    .testCaseSetHeader.active {
      .dragIcon, .expandIcon, .menuIcon {
        visibility: visible;
      }
    }
    .testCaseSetHeader.expanded {
      .expandIcon {
        visibility: visible;
      }
    }
  }
}