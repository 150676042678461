.text_headline1 {
  font-weight: 800;
  font-size: 48px;
  line-height: 56px;
  color: $black;

  @media screen and (max-width: $screen_lg) {
      font-size: 28px;
      line-height: 36px;
  }
}

.text_headline2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: $black;

  @media screen and (max-width: $screen_lg) {
      font-size: 28px;
      line-height: 36px;
  }
}

.text_headline3 {
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  color: $black;

  @media screen and (max-width: $screen_lg) {
      font-size: 22px;
      line-height: 30px;
  }
}

.text_title_big {
  font-weight: bold;
  font-size: 30px;
  line-height: 28px;
  color: $primaryColor;

  @media screen and (max-width: $screen_lg) {
      font-size: 16px;
      line-height: 22px;
  }
}

.text_title {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: $black;

  @media screen and (max-width: $screen_md) {
      font-size: 12px;
      line-height: 18px;
  }
}

.text_subtitle_big {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: $black;

  @media screen and (max-width: $screen_md) {
      font-size: 12px;
  }
}

.text_subtitle {
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: $black;
}

.text_subtitle_semi_bold {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: $black;
}

.text_subtitle_medium {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: $black;
}

.text_subtitle_charcoal_semi_bold {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: $charcoalGrey;
}

.text_subtitle_light {
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: $dark-grey;
}

.text_subtitle_light_grey_semi_bold {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: $light-grey;
}

.text_body_big {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: $black;
}

.text_body_big_bold {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: $black;
}

.text_body_big_light {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: $dark-grey;
}

.text_body_big_light_semi_bold {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: $dark-grey;
}

.text_body_big_light_bold {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: $dark-grey;
}

.text_body {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: $black;
}

.text_body_bold {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: $black;
}

.text_body_light {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: $dark-grey;

  @media screen and (max-width: $screen_md) {
      font-size: 10px;
      line-height: 14px;
  }
}

.text_body_color {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: $black;
  text-align: center;

  @media screen and (max-width: $screen_md) {
      font-size: 10px;
      line-height: 14px;
  }
}

.text_hint {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: $black;
}

.text_hint_light {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: $dark-grey;
}

.text_caption_bold {
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  color: $black;
}

.text_caption {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: $dark-grey;
}

.text_caption_light {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: $light-grey;

  @media screen and (max-width: $screen_md) {
      font-size: 8px;
  }
}

.text_caption_error {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: $red;
}

.text_caption_small_light {
  font-size: 8px;
  font-weight: 700;
  line-height: 12px;
  color: $light-grey;
}

.text_tag {
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: $light-grey;
}

.text_link_base {
  color: #3e92f2;

  &:hover {
      color: #3e92f2;
  }
}

.text_link_big {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  @extend .text_link_base;
}

.text_link {
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  @extend .text_link_base;
}

.text_link_semi_bold {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  @extend .text_link_base;
}

.text_link_small {
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  cursor: pointer;
  @extend .text_link_base;
}

.hover_under_line {
  text-decoration: none;
  cursor: pointer;

  &:hover {
      text-decoration: underline;
      cursor: pointer;
  }
}

.text_label {
  font-weight: 600;
  font-size: 12px;
  color: #282A2C;
}

.no_decoration {
  text-decoration: none;
}

.border_box1 {
  @media screen and (min-width: $screen_md) {
      margin: auto;
      padding: 24px;
      box-sizing: border-box;
      border: 1px solid #B6B9BB;
      max-width: 640;
      border-radius: 4px;
  }
}

.line_light {
  @media screen and (max-width: $screen_md) {
      margin-top: 30px;
      border-top: 0.5px solid #B6B9BB;
  }
}

.border_box2 {
  margin: 24px auto;

  @media screen and (min-width: $screen_md) {
      padding: 24px;
      box-sizing: border-box;
      border: 1px solid #B6B9BB;
      max-width: 640;
      border-radius: 4px;
  }
}

.red {
  color: $red !important;
}

.text_green {
  color: "#2CAC0C"
}

.text_status_green {
  color: "#2CAC0C";
  font-weight: 600;
}

.text_red {
  color: "#CE4141"
}

.text_status_red {
  color: "#CE4141";
  font-weight: 600;
}
