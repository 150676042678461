body {
  background-color: #fbfbfb;
}

@media (min-width: 991.98px) {
  main {
    padding-left: 240px;
  }
}

/* Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 58px 0 0; /* Height of navbar */
  width: 240px;
  z-index: 600;
}

.margin-set {
  margin-left: auto;
}

.menu-item {
  /* width: auto !important; */
  text-align: center;
  transition: color 0.3s;

  .ps-menu-button {
    height: 35px !important;
    margin: 10px 9px;
  }
}

.ps-menu-button {
  height: auto;
  padding: 5px !important;
  margin: 5px;
  border-radius: 5px;
}

@media (max-width: 991.98px) {
  .sidebar {
    width: 100%;
  }
}

.sidebar .active {
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  /* padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; Scrollable contents if viewport is shorter than content. */
}

.menu-item:hover {
  color: black !important;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000; /* Ensure the modal is on top of other content */
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  position: relative;
}

.MuiTabScrollButton-root.MuiTabScrollButton-horizontal.Mui-disabled.MuiTabs-scrollButtons {
  display: none;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: gray;
}

/* CollectionSidebar FolderComponent Components Styles */
.action-item, .hover-effect {
  border-left: 2px solid transparent;
}

.action-item:hover #moreIcon {
  visibility: visible !important;
}

.hover-effect:hover {
  background-color: #e9e9e9;
}

.selected-item {
  border-left: 2px solid #6241D4;
  background-color: #f2f2f2;
}

