.customTable {
  // border-bottom: none;
  .toolbarContainer {
    width: 100%;
    padding: 16px 0 0;
  }
  thead {
    background: rgba(0, 0, 0, 0.03);
    height: 40px;
    font-family: "Inter";
    tr {
      font-family: "Inter";
      :nth-child(1) {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      :last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      th {
        p {
          margin-bottom: 0px;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }
  }
  tbody {
    font-family: "Inter";
    tr {
      font-family: "Inter";
      border-bottom: 1px solid rgba(0, 0, 0, 0.03);
      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }
      &:last-child {
        border-bottom: none;
      }
      .MuiTypography-body1, .MuiTypography-body2 {
        font-family: Inter;
        font-size: 13px;
        text-transform: capitalize;
      }
      .MuiTypography-body1 {
        font-weight: 480;
      }
      .title {
        .MuiTypography-body1 {
          font-size: 14px;
          font-weight: 550;
          text-transform: none;
        }
        .MuiTypography-body2 {
          font-weight: 480;
          font-size: 13px;
          text-transform: none;
        }
      }
      .chips {
        max-height: 50px;
        overflow: hidden;
        .MuiTypography-body2 {
          font-weight: 480;
          font-size: 12px;
        }
      }
      .lastrun {
        span {
          font-size: 12px;
        }
        .pass {
          background: #DAF2E8;
          span {
            color: rgb(46, 125, 50);
          }
        }
        .fail {
          background: #FEE2E2;
          span {
            color: rgb(211, 47, 47);
          }
        }
      }
    }
  }
}