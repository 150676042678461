.metadataHeader {
  color: rgba(47, 47, 47, 1);
  .code, .status span {
    font-size: 12px;
    color: rgba(47, 47, 47, 1);
  }
  .title {
    font-size: 24px;
    margin-bottom: 4px;
    font-weight: 500;
    line-height: 29px;
    outline: none;
    &.edit {
      max-height: 29px;
      overflow: auto;
    }
    &.truncate-1-lines {
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .avatar, .avatar svg {
    width: 20px;
    height: 20px;
    color: rgba(47, 47, 47, 1);
  }
  p {
    font-size: 12px;
    color: rgba(47, 47, 47, 1);
    background-color: white;
  }
  .tags {
    margin-top: 0;
    >div {
      height: 20px;
      margin-bottom: 0;
      >span {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}
