.guideModal {
  border-radius: 16px;
  .contentWrapper {
    height: 550px;
    overflow: hidden;
  }
  .title {
    span {
      font-size: 20px;
      font-weight: 500;
    }
    .closeIcon {
      position: absolute;
      right: 20px;
      cursor: pointer;
    }
  }
  .menuIcon {
    height: 20px;
    width: 20px;
    display: inline;
    background-color: white;
    margin-right: 8px;
    // background-color: rgba(28, 34, 41, 1);
    background-color: rgb(100, 116, 139);
    &.mui {
      background-color: transparent;
      // color: rgba(28, 34, 41);
      color: rgb(100, 116, 139);
      width: 21px;
      height: 21px;
    }
  }
  .menu {
    width: 180px;
    display: inline-block;
    vertical-align: top;
    .menuItem {
      padding: 8px;
      width: 180px;
      height: 44px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 400;
      span {
        color: rgba(100, 116, 139, 1);
      }
      &.selected {
        background-color: rgba(151, 71, 255, 1);
        color: #fff;
        .menuIcon {
          background-color: #fff;
          &.mui {
            background-color: transparent;
            color: #fff;
          }
        }
        span {
          font-weight: 600;
          font-size: 14px;
          color: #fff;
        }
      }
    }
  }
  .apiCollection {
    -webkit-mask-image: url("../../../assets/icons/svg/collection.svg");
    mask-image: url("../../../assets/icons/svg/collection.svg");
  }
  .productDocs {
    -webkit-mask-image: url("../../../assets/icons/svg/docs.svg");
    mask-image: url("../../../assets/icons/svg/docs.svg");
  }
  .testScenarios {
    -webkit-mask-image: url("../../../assets/icons/svg/chart.svg");
    mask-image: url("../../../assets/icons/svg/chart.svg");
  }
  .testPlans {
    -webkit-mask-image: url("../../../assets/icons/svg/testPlan.svg");
    mask-image: url("../../../assets/icons/svg/testPlan.svg");
  }
  .monitoring {
    -webkit-mask-image: url("../../../assets/icons/svg/monitoring.svg");
    mask-image: url("../../../assets/icons/svg/monitoring.svg");
  }
  .divider {
    border-color: rgba(28, 34, 41, 0.1);
  }
  .content {
    width: calc(100% - 220px);
    padding: 0px 32px;
    display: inline-block;
    overflow-y: auto;
    min-height: 400px;
    max-height: 400px;
    .title {
      h3 {
        font-weight: 600;
        font-size: 24px;
        color: rgba(28, 34, 41, 1);
        display: inline-block;
        vertical-align: top;
      }
      .menuIcon {
        display: inline-block;
        background-color: rgba(28, 34, 41, 1);
        width: 30px;
        height: 30px;
        mask-size: 30px;
        &.mui {
          background-color: transparent;
          color: rgba(28, 34, 41, 1);
        }
      }
    }
    p {
      margin-top: 16px;
    }
    p, li {
      font-size: 16px;
      font-weight: 400;
      color: rgba(28, 34, 41, 1);
      span {
        font-size: 16px;
        font-weight: 400;
        color: rgba(151, 71, 255, 1);
      }
    }
  }
  .guideContent {
    overflow: hidden;
    p.footer {
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      padding-top: 14px;
    }
  }
}