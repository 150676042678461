$primaryColor: #6241d4;
$charcoalGrey: #3a3f42;
$coolGrey: #b6b9bb;
$battleshipGrey: #707274;
$rosyPink: #ef5d60;
$turquoise: #00c4b5;
$paleGrey: #eef0f2;
$lightGrey: #e7eceb;
$sunflowerYellow: #fcd900;
$darkSkyBlue: rgb(68, 190, 219);
$textColor: #5d6180;
$bigerText: #575757;
$backgroundColor: #f3f6ff;

$turquoise-light: rgba(0, 196, 181, 0.1);
$rosyPink-light: rgba(239, 93, 96, 0.1);
$darkSkyBlue-light: rgba(68, 190, 219, 0.1);
$sunflowerYellow-light: rgba(252, 217, 0, 0.15);
$sicklyYellow-light: rgba(203, 225, 79, 0.15);
$battleshipGrey-light: rgba(112, 114, 116, 0.3);

$turquoise-dark: #02a89b;
$turquoise40: #00c4b5;
$turquoise10: #00c4b5;

$blue-dark: #2c6bf3;
$blue40: #437efe;
$blue10: #437efe;

$red10: #f94545 10%;
$yellow10: #ffac12 10%;
$orange10: #fb662b 10%;
$green10: #17bf63 10%;

$blue: #437efe;
$red: #f94545;
$yellow: #ffac12;
$orange: #fb662b;
$green: #17bf63;

$black: #282a2c;
$dark-grey: #707274;
$light-grey: #b6b9bb;
$ex-light-grey: #e7eceb;
$pale-grey-1: #f3f4f5;
$pale-grey-2: #f8f8f8;
$pale-grey-3: #fafbfc;
$white: #fff;

$screen_xs: 360px;
$screen_sm: 0px; //0 because can be used something between 360 and 872 maybe 600;
$screen_md: 872px;
$screen_lg: 1032px;
$screen_xl: 1200px;

*,
body {
  font-family: "Inter", sans-serif;
  font-size: small;
}

span, p, div, label, li, h1, h2, h3, h4, h5, h6, td, th, tr, input, input *, button, div * {
  font-family: "Inter", sans-serif;
  font-weight: 480;
}

input {
  color: $black !important;
}

select {
  font-family: 'Inter', sans-serif;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.blank_layout {
  margin-top: 80px;
  @media screen and (max-width: $screen_lg) {
    margin: 0px auto 80px;
  }
}

.primary-color {
  color: $primaryColor !important;
}

.border-55-r {
  border-radius: 55px !important;
}

.icon-right {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  transition: right 0.3s; /* Add a transition for smooth movement */
}

.icon-left {
  right: auto;
  position: absolute;
  top: 50%;
  left: 10px;
  display: flex;
  align-items: center;
  transform: translateY(-50%);
  transition: left 0.3s;
}

.transform-50 {
  transform: translateY(-50%);
}

.transform-10 {
  transform: translateY(-10%);
}

.primary-background {
  background-color: $primaryColor !important;
}

.center {
  text-align: center;
}
.terms {
  color: $primaryColor;
  font-weight: bold;
}

.text-color {
  color: $textColor;
}
.table-layout {
  background-color: white;
  border: 1px solid #f3f6ff;
  border-radius: '15px';
  width: 50vw;
}

.bigger-text {
  color: $bigerText;
}
.body-background {
  background-color: $backgroundColor;
}

.centerContent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-textfield .MuiOutlinedInput-notchedOutline {
  border: none;
}

.req-bar {
  width: 624px;
  color: $primaryColor;
}

.selected-project,
.book-demo-btn,
.upgrade-btn {
  background-color: #6241d4 !important;
  color: #fff !important;
  border-radius: 5px !important;
  width: 185px !important;
  height: 32px !important;
  font-size: 12px !important;
  margin-right: 5px !important;
  line-height: 12px !important;
  padding: 8px 22px !important;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
}

.selected-project {
  margin-right: 0 !important;
}

.book-demo-btn {
  vertical-align: middle;
  border: none;
  color: #fff !important;
  width: 150px !important;
}

.icon-button {
  background-color: transparent;
  border-style: none;
  border: none;
  font-weight: bold;
  cursor: pointer;
  position: absolute !important;
  right: 20px;
}

.expand-item {
  font-size: 30px;
  display: flex;
  justify-content: flex-end;
}
.request-btn {
  height: 120%;
  margin-left: 10px !important;
  background-color: $primaryColor !important;
}
.variable-box {
  min-height: 50% !important;
  overflow-y: auto;
}
.setting-page {
  background-color: green !important;

  overflow-y: none !important;
}

.method-color {
  color: #6241d4 !important;
}

.primarySaveButton {
  margin-top: 0.8% !important;
  height: 5vh;
  margin-left: 10px;
  background-color: $primaryColor !important;
}

.tool-bar-style {
  background-color: white;
  // height: 80px !important;
  // position: fixed;
  // padding-left: 40px !important;
  // padding-right: 40px !important;
  // padding-top: 20px !important;
  // padding-bottom: 20px !important;
}
.side-bar-style {
  border: 5px;
  height: 110px;
  position: fixed;
  top: 0;
}

.sidebar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 150px;
  height: 100vh;
  background-color: #6241d4;
  z-index: 100;
  overflow-y: auto;
}
.toolbar-container {
  top: 0;
  left: 0;
  z-index: 100;
  border-bottom: 1px solid #e9e9e9;
}

.page-container {
  display: flex;
}

.main-content-container {
  height: 100vh;
  display: flex;
  overflow: hidden;
  background-color: #fff;
}

.main-content {
  margin-left: 8%;
}

.tab-style {
  background-color: #fff;
  min-height: 28px !important;
  margin: 5px 16px;
  border-radius: 5px;
  display: flex;
  width: fit-content;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .tab-style {
    flex-direction: column;
    align-items: stretch;
  }

  .tab-style > * {
    width: 100%;
    margin-bottom: 5px;
  }
}

.textLarge {
  font-size: '30px';
}

.primary-border {
  border: 1px $primaryColor !important;
  height: '120%';
}

.search-bar,
.search-bar:focus {
  border: none !important;
  outline: none !important;
}

.flex-space {
  display: flex;
  justify-content: space-between;
}

.modal {
  /* Your modal styles */
  position: relative;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
}

.default_layout {
  padding: 16px;
  margin-top: 80px;
  @media screen and (max-width: $screen_lg) {
    margin: 0px auto 80px;
  }
}

.feed_layout {
  padding: 16px;
  margin-top: 80px;
  @media screen and (max-width: $screen_lg) {
    margin: 0px auto 80px;
  }
}

.pre_auth_layout {
  padding: 0px 16px;
  margin-top: 48px;
  @media screen and (max-width: $screen_md) {
    margin: 32px auto;
  }
}

// Test case styles
.test-case-rhs-input .MuiButtonBase-root {
  min-width: 0px !important;
  margin-right: -10px !important;
}

.test-failure-dialog {
  min-height: 250px;
  max-height: 500px;
}

.test-failure-dialog .MuiTypography-root {
  text-wrap: wrap;
}
////////////////////

// SyntaxHighlighter overrides
code {
  white-space: pre-wrap !important;
}

.search-input {
  border: 2px solid rgb(212 212 212);
  border-radius: 10px;
  padding: 5px 14px;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.search-input-w-icon {
  border: 2px solid rgb(212 212 212);
  border-radius: 10px;
  padding: 5px 36px 5px 16px;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  width: 400px;
  box-sizing: border-box;
}

.truncate-2-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.truncate-1-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
}

@import './typography.scss';
@import './inputbox_styles.scss';
@import './stable_styles.scss';
@import './common.scss';
