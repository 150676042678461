.customDivider {
  border-color: #4d4d4d;
  opacity: 0.3;
  &.horizontal {
    border-width: 100%;
    height: 1px;
  }
  &.vertical {
    width: 1px;
    border-width: 16px;
  }
}