.functionModal {
  h6 {
    margin-top: 16px;
  }
  .jsEditor {
    border: 1px solid #e4e4e4;
    background-color: #e4e4e4;
  }
  .error {
    color:rgb(175, 3, 3);
  }
}