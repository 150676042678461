@mixin placeholder {
    &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
      @content;
    }
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      @content;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
      @content;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      @content;
    }
  }
  
  @mixin an-placeholder {
    @include placeholder {
      color: $coolGrey;
    }
    &:focus{
      @include placeholder {
        color: transparent;
      }
    }
  }
  .text_body_placeholder{
    font-weight: 600;
    font-size: 14px;
    color: $coolGrey;
  }
  .an_input_box{
    position: relative;
    padding-top: 6px;
    padding-bottom: 8px;
    .an_input_label{
      @extend .text_caption_light;
      position: absolute;
      top: -20px;
      left: 0px;
      width: 100%;
      text-align: left;
    }
    .an_input_label_dummy{
      @extend .text_caption_light;
      padding-bottom: 4px;
      opacity: 0;
      width: 100%;
    }
    .mentions{
      div{
        padding: 8px;
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
      }
      @extend .text_body;
      width: 100%;
    }
    input, input[type='text'], input[type='password'], input[type='number'], textarea, select, .selected_token, .chosen-choices{
      display: block;
      @extend .text_body;
      color: $black !important;
      border: 1px solid $coolGrey;
      width: 100%;
      padding: 9px 12px;
      background: #F8F8F8;
      border-radius: 3px;
      @include an-placeholder();
      margin: 0px;
      box-sizing: border-box;    
      @media screen and (max-width: $screen-md){
        // width: auto;
        border: 0px solid $coolGrey;
      }
      &:focus{
        outline: none;
        border: 1px solid #437EFE;
        box-shadow: 0px 0px 4px rgba(67, 126, 254, 0.4);
        border-radius: 4px;
        background-color: #fff;
        ~ .an_input_label{
          @extend .text_caption;
        }
      }
      &.loading{
        padding-right: 36px;
      }
      &:disabled, &.disabled, &[disabled='disabled']{
        background-color: #fff;
        &:hover{
          cursor: not-allowed;
        }
      }
    }
    .mentions ~ textarea{
      background: transparent;
    }
    select{
      background: #F8F8F8 url('../assets/darkDropdown.svg') no-repeat right;
      padding-right: 24px;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      &:disabled, &.disabled, &[disabled='disabled']{
        background-color: #fff;
        &:hover{
          cursor: not-allowed;
        }
      }
      @media screen and (max-width: $screen-md){
        background: #F8F8F8 url('../assets/GreyArrowDown.svg') no-repeat right;
      }
    }
    select.unselected{
      @extend .text_body_placeholder;
      option{
        @extend .text_body;
      }
    }
    &.an_input_error{
      input[type='text'], input[type='password'], textarea, select{
        border: 1px solid #ef5d60;
      }
    }
    .an_helper_message{
      @extend .text_caption_light;
      padding-top: 4px;
    }
    textarea{
      max-height: 200px;
      min-height: 100px;
    }
  }
  
  .an_search_box{
    @extend .an_input_box;
    input[type='text'], input[type='number'], textarea, select, .selected_token{
      padding: 0px;
      padding-left: 48px;
      border: none;
      height: 40px;
  
    //   &:focus{
    //     border: none;
    //   }
    }
    .selected_token{
      padding: 5px 24px;
      line-height: 70px;
    }
    .an_search_lens{
      position: absolute;
      bottom: 50%;
      left: 12px;
    }
  }
  
  .an_large_search_box{
    @extend .an_input_box;
    input[type='text'], input[type='number'], textarea, select, .selected_token{
      padding: 0px;
      padding-left: 72px;
      border: none;
      height: 80px;
      font-size: 18px;
      &:focus{
        border: none;
      }
    }
    .selected_token{
      padding: 5px 24px;
      line-height: 70px;
    }
    .an_search_lens{
      font-size: 32px;
      color: $turquoise;
      position: absolute;
      top: 24px;
      left: 24px;
    }
  }
  
  .an_search_lens{
    font-size: 16px;
    color: #bbb;
    position: absolute;
    top: 8px;
    left: 8px;
  }
  .vertical_middle{
    height: 100%;
    vertical-align: middle;
    display: inline-block;
  }
  
  .suggestions_container{
    position: absolute;
    margin-top: 8px;
    width: 100%;
    padding: 0px;
    max-height: 300px;
    // overflow: auto;
    background-color: #fff;
    z-index: 99;
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.1);
  }
  
  .suggested_item{
    cursor: pointer;
    @extend .text_body_big;
    padding: 20px 18px;
    &.active_option{
      background: #e7eceb;
    }
  }
  
  .suggested_item_hover{
    &:hover{
      background: #e7eceb;
    }
  }
  
  .green_border{
    border: 2px solid $turquoise;
  }
  
  .file_upload_container{
    position: relative;
    border: 1px dashed #B6B9BB;
    background: #FAFBFC;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px;
    border-radius: 4px;
    input{
      display: block;
      width: 100%;
      border: none;
      text-transform: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      &:focus {
        outline: none;
      }
    }
  }
  
  .an_input_checkbox{  
    label{
      display: flex;
      align-items: flex-start;
      cursor: pointer;    
    }
    .label{
      @extend .text_body;
      padding-left: 8px;    
      display: block;
      width: calc(100% - 16px);
    }
  
    input[type='checkbox']{
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      display: none;
      ~ .checkbox{      
        height: 16px;
        min-width: 16px;
        margin: 1px 0px;
        background: $paleGrey;
        position: relative;
        box-sizing: border-box;
        border: 1px solid rgba(58,63,66,0.25);
        border-radius: 1px;
        transition: all 0.8s ease;
        cursor: pointer;
        .tick{
          display: none;
        }
      }
      &:checked{
        ~ .checkbox{
          background: $turquoise;
          border: none;
          .tick{
            display: block;
            position: absolute;
            color: #fff;
            top: 0px;
            img{
              display: block;
            }
          }
        }
      }
      &:disabled{
        ~ .checkbox{
          opacity: 0.3;
        }
        &:hover{
          cursor: not-allowed;
        }
      }
    }
  }
  
  
  .an_input_radio{
  
    label{
      @extend .flex;
      width: max-content;
      cursor: pointer;
    }
    input[type='radio']{
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      display: none;
      ~ .radio{
        height: 16px;
        width: 16px;
        margin: 1px 0px;
        background: $paleGrey;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        box-sizing: border-box;
        border: 1px solid rgba(58,63,66,0.25);
        border-radius: 8px;
        transition: all 0.8s ease;
      }
      &:checked{
        ~ .radio{
          border: 1px solid $turquoise;
          .inner_radio{
            position: absolute;
            height: 10px;
            width: 10px;
            background: $turquoise;
            border-radius: 5px;
            top: 2px;
            left: 2px;
          }
        }
      }
      &:disabled{
        ~ .radio{
          opacity: 0.3;
        }
        &:hover{
          cursor: not-allowed;
        }
      }
    }
  }
  
  .smooth_transitioner{
    transition: all 0.8s ease;
  }
  
  
  .date_calendar_box{
    top: 40px;
    left: 0px; 
    margin-top: 8px;
    padding: 24px;
    min-width: 330px;
    @media screen and (max-width: $screen-md){
      left: -40%;
      min-width: 230px;
    }
  }
  
  @media (max-width: $screen_md) {
    .responsive-calendar {
      font-size: 6px !important;
    }
  }
  
  @media (max-width: $screen_xl) {
    .responsive-calendar {
      font-size: 8px !important;
    }
  }
  
  @media (max-width: $screen_lg) {
    .responsive-calendar {
      font-size: 7px !important;
    }
  }