.floatingMenu {
  .floatingButton {
    position: absolute;
    width: 60px;
    height: 62px;
    padding: 0;
    bottom: 24px;
    right: 20px;
    border-radius: 50%;
    z-index: 9;
    animation: blink 1s infinite;
    svg {
      width: 30px;
      height: 30px;
    }
  }
}
@keyframes blink {
  from {opacity: 0.7;}
  50% {opacity: 1;}
  to {opacity: 0.7;}
}