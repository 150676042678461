/* Example Custom Sidebar Styles */
.custom-sidebar {
    /* Your custom styles for the sidebar */
    background-color: #4861bc;
    width: 240px;
  }
  
  .custom-menu-item {
    /* Your custom styles for menu items */
    /* For example, you can set the height and padding here */
    height: 64px;
    padding: 16px;
  }
  
  .custom-menu-item-text {
    /* Your custom styles for the text inside menu items */
    /* For example, you can change the font size and color here */
    font-size: 16px;
    color: #000;
  }
  