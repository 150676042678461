body {
  background: #F3F6FF;
  background: -webkit-linear-gradient(to right, #F3F6FF, #F3F6FF);
  background: linear-gradient(to right,  #F3F6FF,  #F3F6FF);
}

.full-height {
  height: 100vh;
}

.column-margin {
  margin: 0 2%;
  padding-left: 0px !important;
}

.grid-card {
  background-color: #ffffff;
  min-height: 700px !important;
}

/* Left Column Styles */
.left-column {
  padding: 40px;
  background-color: #ffffff;
}

.centered-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

/* Heading Container Styles */
.heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.create-account-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.signin-link-container {
  font-size: 14px;
}

.signin-link {
  color: #0066cc;
  text-decoration: none;
  margin-left: 5px;
}

.responsive-image {
  max-width: 85%;
  height: auto;
  display: block;
  margin: 0 auto;
}

/* Right Column Styles */
.right-column {
  padding: 40px;
  background-color: #ffffff;
}

.card-height {
  height: 100%;
}

.input-container {
  margin-bottom: 20px;
}

.checkbox-label {
  font-size: 14px;
}

.google-login-button {
  width: 100%;
  padding: 10px;
  background-color: #4285f4;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.google-login-button:hover {
  background-color: #357ae8;
}

/* Line Container Styles */
.line-container {
  display: flex;
  align-items: center;
  margin: 10px 0;
  width: 100%;
}

.cutting-line {
  flex-grow: 1;
  height: 1px;
  background-color: #ccc;
}

.overlay-text {
  background-color: white;
  padding: 0 10px;
  font-size: 14px;
  position: relative;
}

/* Overrides */
.column-margin li.MuiListItem-root {
  padding: 0;
}

.column-margin .MuiListItemIcon-root {
  min-width: 25px;
}

.left-column .MuiTypography-root {
  font-size: 14px !important;
}

.privacy_text .MuiTypography-root {
  font-size: 14px !important;
}