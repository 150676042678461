.sideDrawer {
  z-index: 9;
  position: fixed;
  height: 100vh;
  top: 100px;
  right: 0;
  .drawerMenuContainer {
    position: absolute;
    right: 0;
    background: white;
    width: 86px;
    height: 100vh;
    border-left: 1px solid #e4e4e4;
    padding: 24px 8px;
    z-index: 0;
  }
  .drawerItem {
    text-align: center;
    padding: 14px 8px;
    height: 70px;
    width: 70px;
    margin: 0 auto;
    margin-bottom: 4px;
    font-size: 14px;
    svg {
      width: 22px;
      height: 22px;
    }
    &:hover,
    &.selected {
      background: #f3f3f3;
      border-radius: 4px;
      cursor: pointer;
      font-weight: 500;
      div {
        color: rgba(79, 70, 229, 1);
      }
      svg {
        color: rgba(79, 70, 229, 1);
      }
    }
  }
  .drawerContent {
    background: white;
    box-shadow: -2px 0px 6px -2px #4d4d4d;
    max-height: calc(100vh - 110px);
    flex-direction: row-reverse;
  }
}
