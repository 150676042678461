.testRulesSuggestions {
  .title {
    font-family: Inter;
    .truncate-1-lines, .suggestedTag span {
      font-weight: 400;
    }
  }
  .editorHead {
    width: 100%;
    font-size: 12px;
    height: 24px;
    background: #4E4E4E;
    color: #CCCCCC;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 2px 8px;
  }
  .editorBody {
    height: auto;
    background: #2D2D2D;
    border: none;
    outline: none;
    color: white;
    width: 100%;
  }
}
