.loading-dots {
    display: inline-flex;
    margin-left: 5px; /* Space between text and dots */
  }
  
  .dot {
    width: 8px;
    height: 8px;
    margin: 0 2px;
    background-color: gray;
    border-radius: 50%;
    animation: dot-blink 1s infinite;
  }
  
  .dot-1 {
    animation-delay: 0s;
  }
  
  .dot-2 {
    animation-delay: 0.2s;
  }
  
  .dot-3 {
    animation-delay: 0.4s;
  }
  
  @keyframes dot-blink {
    0%, 100% {
      transform: scale(1);
      opacity: 0.5;
    }
    50% {
      transform: scale(1.5);
      opacity: 1;
    }
  }
  