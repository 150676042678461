.navigationDrawer {
  font-family: Inter;
  .listItem {
    padding: 2px 8px;
  }
  .bottomNavContainer {
    position: absolute;
    background-color: #fff;
    bottom: 0px;
    width: 100%;
    .bottomNav {
      padding-bottom: 16px;
      .welcome {
        font-weight: 500;
        margin-left: 12px;
        .greeting {
          font-size: 12px;
          color: #64748B;
        }
        .name {
          font-size: 14px;
          color: #081021;
          max-width: 120px;
        }
      }
      .action .listItemIcon {
        min-width: 30px;
        margin-left: 16px;
      }
      .creditListItem {
        padding: 2px 6px;
        .creditInfo {
          padding: 8px 0px;
          width: 100%;
          font-family: Inter, sans-serif;
          font-weight: 550;
          font-size: 11px;
          text-align: center;
          color: rgba(0, 0, 0, 0.87);
          a {
            margin-left: 2px;
            text-decoration: none;
            font-weight: 600;
            font-family: Inter;
            // display: block;
          }
        }
      }
    }
  }
  .topNav {
    padding-top: 16px;
    .listItem.logo {
      margin-bottom: 8px;
    }
    .listItemButton {
      padding-left: 0;
      &:hover {
        background: #fff;
      }
    }
    .listItemIcon {
      min-width: 30px;
      margin-left: 64px;
      padding: 4px;
      border: 1px solid rgba(0, 0, 0, 0.04);
      border-radius: 50%;
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        color: #575757;
      }
    }
  }
  .list {
    .listItem {
      a {
        width: 100%;
      }
      .listItemButton, .book-demo-button {
        padding-left: 24px;
        padding-right: 24px;
        color: #575757;
        font-weight: 500;
        cursor: pointer;
        border-radius: 10px;
        &.selected {
          background-color: #6241D4;
          padding-right: 24px;
          padding-left: 24px;
          span {
            color: #fff;
          }
          .listItemIcon {
            min-width: 30px;
            margin-right: 8px;
            svg {
              color: #fff;
            }
          }
          &:hover {
            background-color: #6241D4;
          }
        }
        span {
          color: #575757;
          font-weight: 500;
          font-size: 14px;
          font-family: Inter;
        }
        .listItemIcon {
          min-width: 30px;
          margin-right: 8px;
          svg {
            width: 20px;
            height: 20px;
            color: #575757;
          }
        }
      }
      .book-demo-button, .selectProjectButton {
        padding: 10px 24px;
        width: 100%;
        background-color: #fff;
        color: #575757;
        border: 1px solid #6241D4;
        color: #6241D4;
        text-align: center;
        font-size: 14px;
        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }
      }
    }
  }
  .selectProjectButton {
    width: 100%;
    background-color: rgba(2, 6, 23, 0.1);
    border-radius: 10px;
    color: rgba(92, 52, 167, 1);
    box-shadow: none;
    text-align: left;
    height: 50px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
    .project {
      text-transform: capitalize;
      .projectName {
        font-size: 14px;
        font-weight: 600;
        width: 170px;
        line-height: 20px;
      }
      
      .orgName {
        font-size: 10px;
        font-weight: 500;
        color: rgba(100, 116, 139, 1);
        width: 170px;
        line-height: 20px;
      }
      
    }
  }
  .treeItemButton {
    padding: 0px 8px;
    border-radius: 8px;
    >div.MuiTreeItem-content .MuiTreeItem-label {
      margin-left: 12px;
    }
    .MuiTreeItem-content {
      padding: 12px 26px;
      border-radius: 8px;
      div {
        font-size: 14px;
        font-family: Inter;
        font-weight: 550;
        color: #575757;
      }
      &:hover, &.Mui-focused {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
    .treeItemButtonLeaf {
      margin-top: 4px;
      .MuiTreeItem-content {
        padding: 0px;
      }
      .MuiTreeItem-label {
        padding: 0px;
      }
      .MuiTreeItem-iconContainer {
        display: none;
      }
      a {
        .listItemButton {
          padding: 8px 24px;
        }
      }
      .listItem {
        padding: 0px;
      }
    }
    .listItemButton {
      padding: 4px;
    }
  }
}