.status {
  span:first-child {
    margin-right: 4px;
  }
  span:last-child {
    text-transform: capitalize;
    vertical-align: bottom;
    font-weight: 500;
  }
}
