.login_component_container{
    border: 1px solid #E7ECEB;
    box-sizing: border-box;
    border-radius: 0px;
    max-width: 466px;
    width: 40%;
    height: 50%;
    margin: auto;
    max-width: 464px;
    padding: 32px;
  
    .btn_login{
      margin-top: 0px;
    }
    .margin-left{
      margin-left: 50%;
    }
    .privacy_text{
      font-size: small;
    }
    
    .btn_google_login{
      background-color:  #3e92f2;
      box-sizing: border-box;
      color: white;
      text-align: center;
      text-transform: capitalize;
      font-weight: bold;
      border: none;
      padding: 10px 58px;
      height: 40px;
      font-size: 16px;
      border-radius: 4px;
      width: 100%
    }
    .btn_google_login:hover{
      background: #2781e9;
      color: white;
      cursor: pointer;
    }
  
    .complete_profile_details{
      margin-top: 8px;
    }
  
    @media screen and (max-width: $screen_lg) {
      border: none;
      padding: 4px;
      .btn_login{
        margin-top: 30px;
      }
      .complete_profile_details{
        margin-top: 24px;
      }
    }
  
  }