.ciCDIntegrations {
  padding: 32px;
  h6 {
    font-weight: 600;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.87);
  }
  .actions {
    margin-bottom: 32px;
    button {
      border: 1px solid #e4e4e4;
      background-color: transparent;
      margin-right: 16px;
      margin-top: 8px;
      text-transform: none;
      span {
        font-size: 12px;
        margin-left: 8px;
        color: rgba(0, 0, 0, 0.87);
      }
      svg {
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }
  .sectionWrapper {
    padding: 16px;
    border: 1px solid #e4e4e4;
    border-radius: 8px;
    margin-top: 16px;
    .section {
      width: calc((100% / 2) - 64px);
      h6 {
        margin-bottom: 16px;
      }
      ul {
        margin-top: 16px;
        li {
          color: #575757;
          margin-bottom: 8px;;
        }
      }
    }
    :first-child.section {
      padding: 8px 16px 8px 0px;
      border-right: 1px solid #e4e4e4;
    }
    :last-child.section {
      padding: 8px 0px 8px 16px;
    }
  }
  .curlUrl {
    padding: 16px;
    background-color: #f7f6f6;
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    line-break: anywhere;
  }
}