.testCaseDataSet {
  // border: 1px solid grey;
  // padding: 16px;
  margin-top: 16px;
  .dataSetSelect {
    width: 50%;
  }
  .actions {
    width: 50%;
    display: inline-block;
    text-align: right;
  }
  button {
    margin-left: 4px;
  }
}