.editDetail {
  color: rgba(0, 0, 0, 0.87);
  .tagsContainer {
    .tags {
      padding: 8px;
    }
  }
  .section {
    margin-bottom: 24px;
    h6 {
      font-size: 16px;
    }
  }
  .description {
    margin-bottom: 16px;
    resize: none;
    width: 100%;
    white-space: pre-wrap;
  }
  .notifications {
    .notificationType {
      background: white;
      border: 1px solid #e5e5e5;
      color: rgba(0, 0, 0, 0.87);
      padding: 14px 4px;
      border-radius: 4px;
      &:hover {
        background: white;
        border-color: #6241D4;
      }
      &.selected {
        background: #6241D4;
        color: white;
        border-color: #6241D4;
        &:hover {
          background: #6241D4;
          border-color: #6241D4;
        }
      }
      margin: 0 8px 8px 0;
      font-size: 15px;
    }
  }
  .status {
    span:last-child {
      color: rgba(0, 0, 0, 0.87);
    }
  }
}
